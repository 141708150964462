<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="$t('yh.zichan')" name="first"></el-tab-pane>
      <el-tab-pane :label="$t('yh.djzc')" name="second"></el-tab-pane>
    </el-tabs>
    <el-form :inline="true">
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="loglist" border style="width: 100%;">
      <el-table-column prop="code" :label="$t('yh.bianhao')"></el-table-column>
      <el-table-column
        prop="action_user"
        :label="$t('yh.czy')"
      ></el-table-column>
      <el-table-column
        prop="username"
        :label="$t('yh.yonghu')"
      ></el-table-column>
      <el-table-column
        prop="coin_type"
        :label="$t('yh.bibi') + '/' + $t('yh.fabi')"
      >
        <template slot-scope="{ row }">
          <span v-if="row.coin_type === 'contract'">{{ $t('yh.bibi') }}</span>
          <span v-else-if="row.coin_type === 'legal_tender'">{{
            $t('yh.fabi')
          }}</span>
          <span v-else-if="row.coin_type === 'defi'">{{
            $t('yh.chuangxin')
          }}</span>
          <span v-else-if="row.coin_type === 'usable'">USDT</span>
        </template>
      </el-table-column>
      <el-table-column prop="action_type" :label="$t('caozuo')">
        <template slot-scope="{ row }">
          <span v-if="row.action_type === '0'">{{ $t('yh.jian') }}</span>
          <span v-else-if="row.action_type === '1'">{{ $t('yh.jia') }}</span>
          <span v-else-if="row.action_type === '2'">{{
            $t('yh.dongjie')
          }}</span>
          <span v-else-if="row.action_type === '3'">{{
            $t('yh.jiedong')
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="num" :label="$t('yh.shuliang')"></el-table-column>
      <el-table-column
        v-if="activeName === 'second'"
        prop="reason"
        :label="$t('djjdyy')"
      ></el-table-column>
      <el-table-column
        prop="product_type"
        :label="$t('yh.cplx')"
      ></el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('yh.czsj')"
        :formatter="dateFormat"
      ></el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        page: 1,
        type: 'first'
      },
      loglist: [],
      total: 0,
      loading: true,
      activeName: 'first'
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
  },
  methods: {
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/user/assetslog', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(this.getlang(data.msg))
        } else {
          this.loglist = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 切换Tab
    handleClick(tab) {
      this.queryinfo.page = 1
      this.queryinfo.type = tab.name
      // console.log(tab.name)
      this.getlist()
    }
  }
}
</script>

<style lang="less">
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
</style>
